import React from 'react'

function Footer() {
  return (
    <div>
       <footer className="ftco-footer ftco-section">
            <div className="container">
                <div className="row">
                    <div className="mouse">
                        <a href="#" className="mouse-icon">
                            <div className="mouse-wheel"><span className="ion-ios-arrow-up"></span></div>
                        </a>
                    </div>
                </div>
                <div className="row mb-5">
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4">
                    <h2 className="ftco-heading-2">EcoPeat</h2>
                    <p>Our innovative thinking helps us overcome new challenges in an ever increasing world of demands for new products, growing solutions and reliability of supply.</p>
                    <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                        <li className="ftco-animate"><a href="#"><span className="icon-instagram"></span></a></li>
                    </ul>
                    </div>
                </div>
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4 ml-md-5">
                    <h2 className="ftco-heading-2">Menu</h2>
                    <ul className="list-unstyled">
                        <li><a href="#" className="py-2 d-block">Home</a></li>
                        <li><a href="#" className="py-2 d-block">Indian Cocopeat</a></li>
                        <li><a href="#" className="py-2 d-block">Fresh Coconut</a></li>
                        <li><a href="#" className="py-2 d-block">Coconut Shell Charcol</a></li>
                    </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="ftco-footer-widget mb-4">
                    <h2 className="ftco-heading-2">Help</h2>
                    <div className="d-flex">
                        <ul className="list-unstyled mr-l-5 pr-l-3 mr-4">
                            <li><a href="#" className="py-2 d-block">Ecopeat Block</a></li>
                            <li><a href="#" className="py-2 d-block">Ecopeat Brick</a></li>
                            <li><a href="#" className="py-2 d-block">Ecopeat Fibre</a></li>
                            <li><a href="#" className="py-2 d-block">Coco Peat Block Retail Pack</a></li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">Have a Questions?</h2>
                        <div className="block-23 mb-3">
                        <ul>
                            <li><span className="icon icon-map-marker"></span><span className="text">Indian Cocopeat,
                            No. 2/38B, ST.Thomas Street,
                            V.P.Patnam, Tuticorin-628216,
                            Tamil Nadu, India.</span></li>
                            <li><a href="#"><span className="icon icon-phone"></span><span className="text">+91 99446 70855</span></a></li>
                            <li><a href="#"><span className="icon icon-envelope"></span><span className="text">ecopeat.in@gmail.com</span></a></li>
                        </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-12 text-center">
                    <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script> 
                    All rights reserved | M.S Trade Links</p>
                </div>
                </div>
                <div className="row">
                <div className="col-md-12 text-center">
                
                </div>
                </div>
            </div>
            </footer>
    </div>
  )
}

export default Footer