import { Routes, Route, Redirect } from 'react-router-dom';
import ReactGA from "react-ga4";
import Welcome from './components/Welcome.js';
import Indiancocopeat from './components/IndianCocopeat.js';
import Gallery from './components/Gallery.js';
import Contact from './components/ContactMe.js';
import Header from './Layouts/Header.js';
import Footer from './Layouts/Footer.js';

ReactGA.initialize("G-H2YDJXWTM3");
ReactGA.send("pageview");

function App() {
  return (
    <div>
      <Header/>
      <Routes>
        <Route path='/' element={<Welcome/>}/>
        <Route path='/indiancocopeat' element={<Indiancocopeat/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/contactus' element={<Contact/>}/>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
