import React from 'react'

function Contact() {
  return (
    <div>
        <p style={{color: "green", fontSize: "28px", textDecoration: 'underline', textAlign: 'center'}}>Contact Us</p>
        <section class="ftco-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="product">
                        <h2>Indian Cocopeat</h2>
                            <div>
                                <p>Please feel free to contact us</p>
                                <hr/>
                                <p>2/38B, ST.Thomas Street,</p>
                                <p>V.P.Patnam,</p>
                                <p>Tuticorin.</p>
                                <p>Tamil Nadu,</p>
                                <p>Pin Code : 628 216</p>
                                <p>Phone : +91 99446 70855</p>
                                <p>Email : ecopeat.in@gmail.com</p>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Contact
