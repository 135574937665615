import React from 'react'

import fruits1 from '../assets/gallery/fruits-1.jpeg'
import fruits from '../assets/gallery/cocopeat.jpeg'
import fruits2 from '../assets/gallery/fruits-2.jpeg'
import coconut from '../assets/gallery/fresh_coconut.jpeg'

function Gallery() {
  return (
    <div>
        <p style={{color: "green", fontSize: "28px", textDecoration: 'underline', textAlign: 'center'}}>Gallery</p>
        <section class="ftco-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-3">
                        <div class="product">
                            <img class="img-fluid" src={fruits1} alt="ecopeat"></img>
                            <div class="overlay"></div>
                            <div class="text py-3 pb-4 px-3 text-center">
                                <h3><a href="#">Green Vegetables</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="product">
                            <img class="img-fluid" src={fruits} alt="ecopeat"></img>
                            <div class="overlay"></div>
                            <div class="text py-3 pb-4 px-3 text-center">
                                <h3><a href="#">Fresh Coconut</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="product">
                            <img class="img-fluid" src={fruits2} alt="ecopeat"></img>
                            <div class="overlay"></div>
                            <div class="text py-3 pb-4 px-3 text-center">
                                <h3><a href="#">Tomato</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                        <div class="product">
                            <img class="img-fluid" src={coconut} alt="ecopeat"></img>
                            <div class="overlay"></div>
                            <div class="text py-3 pb-4 px-3 text-center">
                                <h3><a href="#">Coconut</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Gallery