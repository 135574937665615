import React from 'react'

import ecopeatblock from '../assets/products/1.jpg'
import ecopeatbrick from '../assets/products/2.jpg'
import ecopeatfibre from '../assets/products/3.jpg'

function Products() {
  return (
    <div>

<section className="ftco-section">
			<div className="container">
				<div className="row no-gutters ftco-services">
          <div className="col-md-4 text-center d-flex align-self-stretch">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="active d-flex justify-content-center align-items-center mb-2">
                <img alt="ecopeat"src={ ecopeatblock}></img>
              </div>
              <div className="media-body">
                <h3 className="heading">Ecopeat Block</h3>
              </div>
            </div>      
          </div>
          <div className="col-md-4 text-center d-flex align-self-stretch">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="d-flex justify-content-center align-items-center mb-2">
                    <img alt="ecopeat"src={ ecopeatbrick}></img>
              </div>
              <div className="media-body">
                <h3 className="heading">Ecopeat Brick</h3>
              </div>
            </div>    
          </div>
          <div className="col-md-4 text-center d-flex align-self-stretch">
            <div className="media block-6 services mb-md-0 mb-4">
              <div className="d-flex justify-content-center align-items-center mb-2">
                  <img alt="ecopeat"src={ ecopeatfibre}></img>
              </div>
              <div className="media-body">
                <h3 className="heading">Ecopeat Fibre</h3>
                
              </div>
            </div>      
          </div>
        </div>
			</div>
		</section>
    </div>
  )
}

export default Products