import React from 'react'

import indiancocopeat from '../assets/indiancocopeat.jpeg'

function IndianCocopeat() {
  return (
    <div>
        <section className="ftco-section ftco-no-pb ftco-no-pt bg-light">
			<div className="container">
				<div className="row">
					<div className="col-md-2 p-md-2 img img-2 d-flex justify-content-center align-items-center">
                        <img alt="ecopeat"src={ indiancocopeat}></img>
					</div>
                    <div className="col-md-9 py-9 wrap-about pb-md-9">
                        <div className="heading-section-bold mb-4 mt-md-5">
                            <div className="ml-md-0">
                                <h2 className="mb-4">INDIAN COCOPEAT</h2>
                            </div>
                        </div>
                        <div className="pb-md-5">
                            <p>Cocopeat is a natural fibre made out of coconut husks. The extraction of the coconut fibre from husks gives
                               us this by-product called cocopeat. Cocopeat is a 100% natural growing medium.This cocopeat dried in the natural
                               sun, is processed to produce different items namely cocopeat bale, cocopeat bags, cocopeat briquettes,
                               cocopeat tablets etc. of various granularity and denseness, which are then used for horticultural and agricultural 
                               applications and as industrial absorbent. Usually shipped in the form of compressed bales, briquettes, slabs or discs, 
                               the end user usually expands and aerates the compressed coco peat by the addition of water. A single kilogram of coco 
                               peat will expand and retain upto 10-15 litres of water.Coco Peat can used as a multi purpose growing medium for 
                               hydrophonics or container plant growing and as an excellent strata for flower cultivation.</p>
							<p>It is the best alternative to traditional peat moss. Coco peat is the most ideal growing medium for plant crops 
                                because of its high water retention capacity, air filled porosity and a high lignin content and retains its 
                                physical properties for several years. Also it is 100% organic and eco friendly and root friendly product. 
                                Clean coir has natural rooting hormones and anti-fungal properties.</p>
                            <p>Coco Peat (high EC) is used as a bedding in poultry farms, and after use for 3 or 4 months it is removed
                                and used as organic manure. It is widely used as flooring in horse and pig farms. 
                                Due to its composition in winter season it retains warmth which is essential for horse and pigs.</p>
                            <p>
                            To manufacture best Coco Peat we always follow internationally recommended process. We pre wash the fibrous coconut husk, 
                            dry it by machines and check it to make it free from sand and other unwanted things .
                            </p>
						</div>
					</div>
				</div>
			</div>
		</section>
        <hr/>
        <div>
            <h2>Properties of Cocopeat</h2>
            <ul>
                <li>Weed and pathogen free</li>
                <li>Mold and fungal resistant</li>
                <li>High lignin/ cellulose content</li>
                <li>Biodegradable</li>
                <li>Retains just the right amount of water</li>
                <li>Reabsorbs water quickly even when completely dry</li>
                <li>Very slow to disintegrate</li>
                <li>Very light and easy to handle.</li>
            </ul>
            <hr/>
            <h2>Places where cocopeat is Used</h2>
            <ul>
                <li>For golf courses and nursery poly bags</li>
                <li>As a substrata for floriculture and horticulture crops</li>
                <li>Used as flooring in horse and pig farms.</li>
                <li>As a bedding in animal farms and pet houses to absorb animal waste so the farm is kept clean and dry.</li>
                <li>As an oil absorbent on slippery floors in Industries.</li>
                <li>As a potting mix</li>
            </ul>
            <hr/>
            <h2>Advantages of Using Cocopeat</h2>
            <ul>
                <li>A good soil conditioner/improver</li>
                <li>Porous and cannot be overwatered easily.</li>
                <li>Virtually eliminates loss in transplanting</li>
                <li>Provides a number of biological benefits</li>
                <li>Enables flexible crop timing</li>
                <li>Faster growth with more abundant fruit/bloom setting</li>
                <li>Plug design and aeration of medium promote root density and directions</li>
                <li>Provides insulation in harsh conditions, reducing shock.</li>
                <li>Protects roots against drying and damage during storage and shipping</li>
                <li>Affordable price</li>
                <li>Reduces shipping costs.</li>
                <li>More suitable for humid & dry conditions</li>
            </ul>
        </div>
    </div>
  )
}

export default IndianCocopeat