import React from 'react'

import Slider from '../pages/Slider.js'
import Intro from '../pages/Intro.js'
import Products from '../pages/Products.js'
import GalleryImages from '../pages/WelcomeGallery.js'

function Welcome() {
  return (
    <div>
      <Slider/>
      <Intro/>
      <Products/>
      <GalleryImages/>
    </div>
  )
}

export default Welcome