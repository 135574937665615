import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import img1 from '../assets/bg_1.jpg';
import img2 from '../assets/bg_2.jpg';
import img3 from '../assets/bg_3.jpg';

function Gallery() {
  return (
    <div>
        <OwlCarousel className='owl-theme' items="3" loop autoplay nav>
            <div class='item'>
                <img alt="ecopeat"src={img1}/>
            </div>
            <div class='item'>
                <img alt="ecopeat"src={img2}/>
            </div>
            <div class='item'>
                <img alt="ecopeat"src={img3}/>
            </div>
        </OwlCarousel>;
    </div>
  )
}

export default Gallery