import React from 'react'

function Intro() {
  return (
    <div>
        <p>Welcome to Ecopeat
We are one of the largest exporters of Coco Peat from South India. Our office is based at Tuticorin and supplying units are located in the coconut triangle of Southern Tamilnadu. The company has gained goodwill and a credible position in the International Market. We have always been a part of exporter of eco-friendly products in the country and we are geared to fulfill your requirements at a reasonable price. The company has grown steadily over the years, to become a reputed name in the coir industry. We stand poised on the verge of greater growth in the new millennium. We are registered with the Coir Board, India. We have a team of qualified export specialists who are trained to monitor your cargo through every stage of the export activity by sea . We have built up our own export network in India and we are in a position to source and fulfill customer requirements. We pay strict attention to Quality Standards and on-time Delivery and Service.</p>
    </div>
  )
}

export default Intro