import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div>
        <div className="py-1 bg-primary">
    	<div className="container">
    		<div className="row no-gutters d-flex align-items-start align-items-center px-md-0">
	    		<div className="col-lg-12 d-block">
		    		<div className="row d-flex">
		    			<div className="col-md pr-4 d-flex topper align-items-center">
					    	<div className="icon mr-2 d-flex justify-content-center align-items-center"><span className="icon-phone2"></span></div>
						    <span className="text">+91 99446 70855</span>
					    </div>
					    <div className="col-md pr-4 d-flex topper align-items-center">
					    	<div className="icon mr-2 d-flex justify-content-center align-items-center"><span className="icon-paper-plane"></span></div>
						    <span className="text">ecopeat.in@gmail.com</span>
					    </div>
					    <div className="col-md-5 pr-4 d-flex topper align-items-center text-lg-right">
						    <span className="text">24*7 Voice Support</span>
					    </div>
				    </div>
			    </div>
		    </div>
		  </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
	    <div className="container">
	      <Link className="navbar-brand" to="/">Ecopeat</Link>
	      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
	        <span className="oi oi-menu"></span> Menu
	      </button>

	      <div className="collapse navbar-collapse" id="ftco-nav">
	        <ul className="navbar-nav ml-auto">
	          <li className="nav-item active"><Link to="/" className="nav-link">Home</Link></li>
	          <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Products</a>
              <div className="dropdown-menu" aria-labelledby="dropdown04">
                    <a className="dropdown-item">Ecopeat Block</a>
                    <a className="dropdown-item">Ecopeat Block</a>
                    <a className="dropdown-item">Ecopeat Brick</a>
                    <a className="dropdown-item">Ecopeat Fibre</a>
                    <a className="dropdown-item">Coco Peat Block Retail Pack</a>
                    <a className="dropdown-item">Steamed Activated Coconut Shell Charcol</a>
                    <a className="dropdown-item">Indian Cocopeat</a>
                    <a className="dropdown-item">Fresh Coconut</a>
                    <a className="dropdown-item">Coconut Shell Charcol</a>
                    <a className="dropdown-item">Coconut Oil</a>
              </div>
            </li>
            <li className="nav-item"><Link to='/indiancocopeat' className="nav-link">Indian Cocopeat</Link></li>
            <li className="nav-item"><Link to='/gallery' className="nav-link">Gallery</Link></li>
            <li className="nav-item"><Link to='/contactus' className="nav-link">Contact Us</Link></li>
	        </ul>
	      </div>
	    </div>
	  </nav>
	  
    </div>
  )
}

export default Header